import { HttpErrorResponse } from "@angular/common/http";
import { throwError } from "rxjs";

export class ErrorHandlers {
  static handleApiError(errorRes: HttpErrorResponse) {
    let errorMessage = "An unknown error occurred!";
    let errorList = [];

    switch (errorRes.status) {
      case 403:
        errorMessage = "Invalid Login Details";
        break;
      case 400:
        errorList = errorRes.error.errors;
        errorMessage = errorRes.error.message;
        break;
      case 500:
        errorMessage = "Server Error";
    }

    return throwError(errorMessage);
  }
}
