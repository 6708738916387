<div class="cont">
  <header>
    <div class="top-nav bg-white flex justify-end space-x-6 pt-2 lg:pr-12">
      <p>MON - SUN: 9am - 6PM</p>
      <p>+234-706 485 6927</p>
      <div class="flex space-x-2 pr-16">
        <img
          loading="lazy"
          alt="APS Facebook"
          src="../assets/images/fb.png"
          class="w-5 h-5"
        />
        <img
          loading="lazy"
          alt="APS Instagram"
          src="../assets/images/ig.png"
          class="w-5 h-5"
        />
        <img
          loading="lazy"
          alt="APS Twitter"
          src="../assets/images/tt.png"
          class="w-5 h-5"
        />
      </div>
    </div>
    <div class="relative bg-white">
      <div
        class="flex justify-between items-center mx-auto px-6 py-6 sm:px-6 md:justify-start md:space-x-10 lg:px-24"
      >
        <div class="flex justify-start">
          <a href="#">
            <img
              loading="lazy"
              class="h-10 w-auto sm:h-10"
              src="../assets/images/APS Logo.png"
              alt="Agofure logo"
            />
          </a>
        </div>
        <div class="-mr-2 -my-2 md:hidden">
          <button
            type="button"
            (click)="toggleNavbar()"
            class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
          >
            <span class="sr-only">Open menu</span>
            <svg
              class="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </button>
        </div>
        <div class="hidden md:flex items-center justify-end md:flex-1">
          <a
            href="#"
            class="whitespace-nowrap text-base font-medium text-gray-800 hover:text-gray-900 px-4"
          >
            Home
          </a>
          <a
            (click)="scrollToElement(services)"
            class="whitespace-nowrap text-base font-medium text-gray-800 hover:text-gray-900 px-4"
          >
            Services
          </a>
          <a
            (click)="scrollToElement(about)"
            class="whitespace-nowrap text-base font-medium text-gray-800 hover:text-gray-900 px-4"
          >
            About Us
          </a>
          <a
            (click)="scrollToElement(footer)"
            class="whitespace-nowrap text-base font-medium text-gray-800 hover:text-gray-900 px-4"
          >
            Contact Us
          </a>
          <a
            (click)="scrollToElement(quote)"
            class="ml-4 whitespace-nowrap text-base font-medium text-red-500 hover:text-red-900 border-red-500 border px-4 py-2"
          >
            Request a Quote
          </a>
          <a
            target="_blank"
            href="http://aps.agofurelogistic.com"
            class="ml-4 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent shadow-sm text-base font-medium text-white bg-red-500 hover:bg-red-500"
          >
            Sign in
          </a>
        </div>
      </div>

      <!--
        Mobile menu, show/hide based on mobile menu state.

        Entering: "duration-200 ease-out"
          From: "opacity-0 scale-95"
          To: "opacity-100 scale-100"
        Leaving: "duration-100 ease-in"
          From: "opacity-100 scale-100"
          To: "opacity-0 scale-95"
      -->
      <div
        [class]="
          navbarOpen
            ? 'absolute z-30 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden'
            : 'hidden'
        "
      >
        <div
          class="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50"
        >
          <div class="pt-5 pb-6 px-5">
            <div class="flex items-center justify-between">
              <div>
                <img
                  loading="lazy"
                  class="h-8 w-auto"
                  src="../assets/images/APS Logo.png"
                  alt="Agofure Logo"
                />
              </div>
              <div class="-mr-2">
                <button
                  type="button"
                  (click)="toggleNavbar()"
                  class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                >
                  <span class="sr-only">Close menu</span>
                  <!-- Heroicon name: x -->
                  <svg
                    class="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div class="mt-6">
              <nav class="grid grid-cols-1 gap-7">
                <a
                  (click)="scrollToElement(services)"
                  class="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50"
                >
                  <div
                    class="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-600 text-white"
                  >
                    <!-- Heroicon name: inbox -->
                    <svg
                      class="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"
                      />
                    </svg>
                  </div>
                  <div class="ml-4 text-base font-medium text-gray-900">
                    Services
                  </div>
                </a>

                <a
                  (click)="scrollToElement(about)"
                  class="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50"
                >
                  <div
                    class="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-600 text-white"
                  >
                    <!-- Heroicon name: annotation -->
                    <svg
                      class="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
                      />
                    </svg>
                  </div>
                  <div class="ml-4 text-base font-medium text-gray-900">
                    About Us
                  </div>
                </a>

                <a
                  (click)="scrollToElement(quote)"
                  class="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50"
                >
                  <div
                    class="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-600 text-white"
                  >
                    <!-- Heroicon name: chat-alt-2 -->
                    <svg
                      class="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
                      />
                    </svg>
                  </div>
                  <div class="ml-4 text-base font-medium text-gray-900">
                    Request a Quote
                  </div>
                </a>

                <a
                  (click)="scrollToElement(footer)"
                  class="-m-3 p-3 flex items-center rounded-lg hover:bg-gray-50"
                >
                  <div
                    class="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-indigo-600 text-white"
                  >
                    <!-- Heroicon name: question-mark-circle -->
                    <svg
                      class="h-6 w-6"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                  </div>
                  <div class="ml-4 text-base font-medium text-gray-900">
                    Contact Us
                  </div>
                </a>
              </nav>
            </div>
          </div>
          <div class="py-6 px-5">
            <div class="mt-6">
              <p class="mt-6 text-center text-base font-medium text-gray-500">
                Existing customer?
                <a target="_blank"
                href="http://aps.agofurelogistic.com" 
                class="text-gray-900"> Sign in </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>

  <div
    class="modal fade"
    id="exampleModal"
    tabindex="-1"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h3
            class="modal-title text-2xl font-semibold text-center ml-auto"
            id="exampleModalLabel"
          >
            Track Shipment
          </h3>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="d-flex">
            <p class="font-semibold">Tracking Number:</p>
            <p class="ml-2">{{ waybillNumber }}</p>
          </div>

          <form [formGroup]="homeForm">
            <div class="d-flex mt-4">
              <div class="mt-1 w-1/3 mr-8">
                <input
                  type="text"
                  name="email"
                  id="email"
                  formControlName="trackingForm"
                  class="h-auto shadow-sm px-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 border-solid border-1 py-2"
                  placeholder="xxxx-xxx-xxx"
                />
              </div>
              <div class="my-1">
                <button
                  type="button"
                  class="btn btn-secondary px-16 bg-red-500 border-red-500"
                  (click)="getTracking()"
                >
                  Track
                </button>
              </div>
            </div>
          </form>
        </div>

        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th scope="col">SN</th>
                <th scope="col">WAYBILL</th>
                <th scope="col">STATUS</th>
                <th scope="col">LOCATION</th>
                <th scope="col">DATE</th>
                <th scope="col">TIME</th>
              </tr>
            </thead>

            <tbody>
              <div class="d-flex justify-content-center" *ngIf="loading">
                <div class="spinner-border" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>

              <tr *ngFor="let data of tableData; let i = index">
                <td>{{ i + 1 }}</td>
                <td>{{ data?.waybill }}</td>
                <td>{{ data?.statusMessage }}</td>
                <td>{{ data?.location }}</td>
                <td>{{ data?.dateModified | date: "longDate" }}</td>
                <td>{{ data?.dateModified | date: "shortTime" }}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="accordion d-lg-none d-xl-none d-md-none">
          <div
            class="accordion-item"
            *ngFor="let data of tableData; let i = index"
          >
            <h2 class="accordion-header" id="{{ i }}">
              <button
                class="accordion-button"
                type="button"
                data-toggle="collapse"
                [attr.data-target]="'#collapse-' + i"
                [attr.aria-expanded]="false"
                [attr.aria-controls]="'collapse-' + i"
              >
                <strong style="padding-right: 10rem">SN</strong> {{ i + 1 }}
              </button>
            </h2>
            <div
              [attr.id]="'collapse-' + i"
              class="accordion-collapse collapse show"
              [attr.aria-labelledby]="'collapse-' + i"
            >
              <div class="accordion-body">
                <table class="table accord">
                  <tbody>
                    <tr class="tre">
                      <th scope="row">WAYBILL</th>
                      <td>{{ data?.waybill }}</td>
                    </tr>
                    <tr>
                      <th scope="row">STATUS</th>
                      <td>{{ data?.statusMessage }}</td>
                    </tr>
                    <tr>
                      <th scope="row">LOCATION</th>
                      <td>{{ data?.location }}</td>
                    </tr>

                    <tr>
                      <th scope="row">DATE</th>
                      <td>{{ data?.dateModified | date: "longDate" }}</td>
                    </tr>
                    <tr>
                      <th scope="row">TIME</th>
                      <td>{{ data?.dateModified | date: "shortTime" }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <main>
    <alert></alert>
    <div>
      <!-- Hero card -->
      <div class="relative">
        <div class="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100"></div>
        <div class="max-w-full">
          <div class="relative shadow-xl sm:overflow-hidden">
            <div class="absolute inset-0">
              <img
                loading="lazy"
                class="h-full w-full object-cover"
                src="../assets/images/APS Truck.png"
                alt="Agofure hero"
              />
              <div
                class="absolute inset-0 bg-indigo-500"
                style="mix-blend-mode: multiply"
              ></div>
            </div>
            <div class="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
              <h1
                class="text-center text-4xl font-bold tracking-tight sm:text-5xl lg:text-6xl"
              >
                <span class="block text-white">Logistics partner you</span>
                <span class="block text-white">can trust</span>
              </h1>
              <p
                class="mt-6 max-w-lg mx-auto text-center text-xl text-white sm:max-w-3xl"
              >
                We strive to deliver excellent Logistics services for every kind
                of business and provide timed deliveries with complex
                flexibility.
              </p>

              <form [formGroup]="homeForm">
                <div
                  class="mt-10 hero-form max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center flex lg:justify-between h-auto bg-white"
                >
                  <div
                    class="col-span-6 w-full sm:col-span-4 content-center flex flex-wrap"
                  >
                    <input
                      type="text"
                      name="email_address"
                      id="email_address"
                      formControlName="trackingForm"
                      class="block h-full w-full mx-auto px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      placeholder="Tracking Number"
                    />
                  </div>
                  <a
                    (click)="getTracking()"
                    class="flex items-center justify-center px-4 my-2 py-2 w-1/3 text-sm font-medium text-white bg-red-500 bg-opacity-60 hover:bg-opacity-70 sm:px-8"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    Track Now
                  </a>
                </div>
              </form>
              <p
                class="mt-4 max-w-lg mx-auto text-center text-xs tracking-wider text-white sm:max-w-3xl"
              >
                *Enter your 10 digit alpha numeric tracking number*
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <!-- Services Section Here... -->
  <div class="bg-blue-100">
    <div class="lg:mx-40 py-16 px-4 sm:px-6 lg:px-2">
      <div
        class="mt-6 lg:grid lg:grid-cols-2 flex-col gap-4 md:grid-cols-2 lg:grid-cols-2"
      >
        <div class="col-span-1 justify-center md:col-span-2 lg:col-span-1">
          <p class="text-base font-bold text-blue-800">
            Safe and reliable, Logistics Solutions
          </p>
          <h3 class="text-3xl tracking-wide font-bold">
            Delivering the Best Logistics Solutions
          </h3>
        </div>
        <div
          class="col-span-1 pt-6 lg:pt-4 justify-center md:col-span-2 lg:col-span-1"
        >
          <p class="text-base tracking-wide">
            APS helps over 20,000 small businesses deliver their purchases and
            orders to their customers across our inter connected, swift and
            reliable delivery network; spanning across the major cities in
            Nigeria.
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- Our Services Section -->

  <div class="service-section pb-10" #services>
    <div class="lg:mx-40 py-16 px-4 sm:px-6 lg:px-2">
      <p class="text-center text-4xl mt-6 mb-10 font-semibold">Our Services</p>
      <div class="grid lg:grid-cols-3">
        <div
          class="col-span-1 justify-center md:col-span-2 lg:col-span-1 bg-white"
        >
          <div class="h-auto flex flex-col air-freight">
            <div>
              <img
                src="../assets/images/bike.jpg"
                loading="lazy"
                alt="APS Air Freight"
              />
            </div>
            <div class="mt-8 px-4">
              <p class="lg:text-lg text-xl my-2 font-bold text-blue-800">
                Bike Delivery
              </p>
              <p class="lg:text-sm text-base tracking-wide">
                Through our On-demand bike service, we provide expedited
                local delivery within neigborhoods and local government areas.
              </p>
            </div>
          </div>
        </div>
        <div
          class="col-span-1 justify-center md:col-span-2 lg:col-span-1 bg-white"
        >
          <div class="h-auto flex lg:flex-col-reverse flex-col">
            <div>
              <img
                src="../assets/images/Truck.png"
                loading="lazy"
                alt="APS Road Frieght"
              />
            </div>
            <div class="mt-4 mb-16 px-4 road-text">
              <p class="lg:text-lg text-xl my-2 font-bold text-blue-800">
                Truck Delivery
              </p>
              <p class="lg:text-sm text-base tracking-wide">
                Through our International Courier service, we provide expedited
                Cargo and  Baggage delivery facilitated by our trucks
              </p>
            </div>
          </div>
        </div>
        <div
          class="col-span-1 justify-center md:col-span-2 lg:col-span-1 bg-white"
        >
          <div class="h-auto flex flex-col">
            <div>
              <img
                src="../assets/images/bus.jpg"
                loading="lazy"
                alt="APS Ocean Freight"
              />
            </div>
            <div class="mt-8 px-4">
              <p class="lg:text-lg text-xl my-2 font-bold text-blue-800">
                Bus Delivery
              </p>
              <p class="lg:text-sm text-base tracking-wide">
                Across and within states, our buses are always on ground to provide expedited delivery of your parcels.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Quote Section -->
  <div class="relative" #quote>
    <div class="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100"></div>
    <div class="max-w-full">
      <div class="relative shadow-xl sm:overflow-hidden">
        <div class="absolute inset-0">
          <img
            loading="lazy"
            class="h-full w-full object-cover shadow-inner"
            src="../assets/images/Global.png"
            alt="Agofure Quote"
          />
          <div
            class="absolute inset-0 bg-gray-500"
            style="mix-blend-mode: multiply"
          ></div>
        </div>
        <div
          class="relative lg:flex lg:flex-row px-8 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-12"
        >
          <div class="lg:w-1/2 my-8">
            <p class="text-white text-sm font-semibold">Your Prompt Delivery</p>
            <h1
              class="text-2xl font-bold tracking-tight sm:text-5xl lg:text-4xl"
            >
              <span class="block text-white"
                >A Global Logistics Partner To</span
              >
              <span class="block text-white">World's Famous Brands!</span>
            </h1>
            <div class="flex flex-row justify-between mt-8 mb-4">
              <div class="col-span-1">
                <img
                  loading="lazy"
                  alt="APS Transparent Pricing"
                  src="../assets/images/payment-terminal.png"
                />
                <p
                  class="text-white service-text tracking-wide font-semibold overflow-clip"
                >
                  Transparent Pricing
                </p>
              </div>

              <div class="col-span-1">
                <img
                  loading="lazy"
                  alt="APS Efficient Delivery"
                  src="../assets/images/order.png"
                />
                <p
                  class="text-white service-text tracking-wide font-semibold overflow-clip"
                >
                  Fast, Efficient Delivery
                </p>
              </div>

              <div class="col-span-1">
                <img
                  loading="lazy"
                  alt="APS Warehouse Storage"
                  src="../assets/images/pallet.png"
                />
                <p
                  class="text-white service-text tracking-wide font-semibold overflow-clip"
                >
                  Warehouse Storage
                </p>
              </div>
            </div>
            <p class="text-white text-sm tracking-wide">
              We pride ourselves on providing the best shipping services
              available. Our skilled personnel, utilising the latest
              communications, tracking and combined experience through
              intergrated chain solutions!
            </p>
          </div>
          <div class="lg:w-1/2">
            <div class="py-4 lg:px-8 px-1 mt-3">
              <div class="request-card rounded-t-lg lg:w-2/3 lg:mx-auto">
                <div class="py-4 px-6">
                  <div class="flex flex-col">
                    <h4 class="text-lg font-semibold my-6 text-center">
                      Request a Quote
                    </h4>
                    <form [formGroup]="homeForm">
                      <div class="flex flex-col text-sm text-gray-500">
                        <div class="py-1">
                          <select
                            id="location"
                            formControlName="departureTerminalId"
                            aria-placeholder="xx"
                            name="location"
                            class="request-input mt-1 block w-full pl-3 pr-10 py-3 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                          >
                            <option [value]="null" selected disabled>
                              From:
                              <p class="font-semibold">
                                Departure City/Terminal
                              </p>
                            </option>
                            <option
                              *ngFor="let terminal of terminals"
                              [value]="terminal.id"
                            >
                              {{ terminal.name }}
                            </option>
                          </select>
                        </div>

                        <div class="py-1">
                          <select
                            id="location"
                            formControlName="destinationTerminalId"
                            aria-placeholder="xx"
                            name="location"
                            class="request-input mt-1 block w-full pl-3 pr-10 py-3 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                          >
                            <option [value]="null" selected disabled>
                              To:
                              <p class="font-semibold">
                                Destination City/Terminal
                              </p>
                            </option>
                            <option
                              *ngFor="let terminal of terminals"
                              [value]="terminal.id"
                            >
                              {{ terminal.name }}
                            </option>
                          </select>
                        </div>

                        <div class="py-1">
                          <div class="mt-1">
                            <input
                              type="number"
                              formControlName="weight"
                              name="weight"
                              id="weight"
                              class="request-input py-3 pl-3 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              placeholder="Enter value of the weight"
                            />
                          </div>
                        </div>

                        <div class="py-6">
                          <button
                            (click)="getTotalPrice()"
                            [disabled]="!isFormValid"
                            class="block tracking-widest uppercase text-center shadow bg-red-500 hover:bg-red-500 focus:shadow-outline focus:outline-none text-white text-xs py-3 px-2 rounded"
                          >
                            Get Price
                            <span
                              class="spinner-grow spinner-grow-sm ms-1 mb-1"
                              role="status"
                              aria-hidden="true"
                              *ngIf="priceLoading"
                            ></span>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div class="rounded-b-lg bg-white lg:w-2/3 py-2 mx-auto">
                <div class="py-2 px-2">
                  <div class="flex flex-row px-4 justify-between">
                    <h4 class="text-normal font-semibold">Price:</h4>
                    <h4 class="text-normal font-semibold">₦{{ totalPrice }}</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- About Us Section -->
  <div class="about-section pb-10" #about>
    <div class="lg:mx-40 py-16 px-4 sm:px-6 lg:px-2">
      <p class="text-center text-3xl mt-6 mb-10 font-semibold">About Us</p>
      <div class="lg:flex relative">
        <div class="lg:w-1/3 relative">
          <img
            loading="lazy"
            class="about-image w-full rounded-lg object-none z-10"
            src="../assets/images/About Image.png"
            alt="Agofure hero"
          />
          <div class="index rounded-lg bg-gray-200 top-5 left-4 z-0">.</div>
        </div>

        <div class="lg:w-2/3 lg:ml-28 about-text">
          <h3 class="text-3xl tracking-tight font-semibold">
            Safe, Reliable And Swift Delivery Solution
          </h3>
          <h3 class="text-3xl tracking-tight font-semibold">
            You Can Trust Always!
          </h3>

          <div class="lg:flex lg:flex-row my-8 lg:space-x-8">
            <div class="flex-1">
              <p class="lg:text-sm text-base tracking-wide font-extralight">
                We believe a diverse society is a stronger one. We strive to
                ensure our company reflects the cultural diversity of our
                workforce, customers and communities across Nigeria.
              </p>
              <br />
              <p class="lg:text-sm text-base tracking-wide font-extralight">
                We pride ourselves on providing the best transport and logistics
                services available all over Nigeria. Our skilled personnel,
                utilising the latest communications, tracking and processing
                software, combined with decades of experience! Through
                integrated huge fleet of specialized vehicles, Agofure drives
                sustainable competitive advantages to some of Africa's largest
                companies.
              </p>
            </div>
            <div class="flex-1 lg:space-y-16">
              <div class="missions">
                <div class="flex flex-row mb-4">
                  <img
                    loading="lazy"
                    alt="APS Mission"
                    src="../assets/images/Icon.png"
                  />
                  <p class="font-semibold text-lg ml-2">Mission</p>
                </div>
                <p class="lg:text-sm text-base tracking-wide font-extralight">
                  To bring Joy and satisfaction to the face of our customers by
                  surpassing their expactations single everytime.
                </p>
              </div>

              <div class="pt-12">
                <div class="flex flex-row mb-4">
                  <img
                    loading="lazy"
                    alt="APS Vision"
                    src="../assets/images/Icon.png"
                  />
                  <p class="font-semibold text-lg ml-2">Vision</p>
                </div>
                <p class="lg:text-sm text-base tracking-wide font-extralight">
                  Become the foremost parcel delivery service in the sub-saharan
                  Africa delivery joy-filled memories and kindness accross the
                  continent.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="location-section bg-white pb-10" #testimonials>
    <p class="text-center text-3xl mt-6 mb-10 font-semibold">Terminal Locations</p>
    <div class="justify-center flex">
      <div class="accordion w-10/12" id="accordionExample">
        <div class="accordion-item" *ngFor="let terminal of pickupTerminals; let i = index">
          <h2 class="accordion-header" id="{{i}}">
            <button class="accordion-button" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapse-' + i" [attr.aria-expanded]="false" [attr.aria-controls]="'collapse-' + i">
              <strong class="flex">
                <p class="mr-1">
                  <i class="bi bi-info text-lg"></i>
                </p>
                <p>
                  {{terminal.title}}
                </p>
              </strong>
            </button>
          </h2>
          <div [attr.id]="'collapse-' + i" class="accordion-collapse collapse" [attr.aria-labelledby]="'collapse-' + i" data-bs-parent="#accordionExample">
            <div class="accordion-body">
                <div *ngFor="let place of terminal.locations" class="flex mt-1">
                  <a href="{{place.url}}"><i class="bi bi-geo-alt-fill mr-1"></i></a>
                  <p>{{place.location}}</p>
                </div>
            </div>
          </div>
        </div>
    </div>
    </div>
</div>

  <!-- Testimonials Section
  <div class="testimonial-section bg-white pb-10" #testimonials>
    <div class="lg:mx-40 py-16 px-4 sm:px-6 lg:px-2">
      <p class="text-center text-4xl mt-6 mb-10 font-semibold">Testimonials</p>
      <img
        loading="lazy"
        alt="APS Testimonials"
        src="../assets/images/quotes.png"
      />
      <div class="lg:flex lg:flex-row">
        <div class="flex-1">
          <img
            loading="lazy"
            alt="APS Stars"
            src="../assets/images/stars.png"
            class="ml-24 mb-6 mt-2"
          />

          <div class="flex flex-row">
            <img
              loading="lazy"
              alt="APS Male Review"
              class="test-image p-1 inline-block h-14 w-14 rounded-full outline-red-500 ring-4 ring-red-500"
              src="../assets/images/Man.png"
            />
            <span class="ml-10 lg:text-sm text-base">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
                vulputate odio leo, consequat rutrum ipsum ullamcorper ac. Sed
                justo ipsum, lacinia quis pharetra.
              </p>
              <p class="font-semibold text-gray-700">Mr. Peter</p>
            </span>
          </div>
        </div>

        <div class="flex-1 lg:ml-20 lady-section">
          <img
            loading="lazy"
            alt="APS Reviews"
            src="../assets/images/stars.png"
            class="ml-24 mb-6 mt-2"
          />

          <div class="flex flex-row">
            <img
              class="test-image p-1 inline-block h-14 w-14 rounded-full outline-red-500 ring-4 ring-red-500"
              src="../assets/images/Lady.png"
              loading="lazy"
              alt="APS Lady Review"
            />
            <span class="ml-10 lg:text-sm text-base">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam
                vulputate odio leo, consequat rutrum ipsum ullamcorper ac. Sed
                justo ipsum, lacinia quis pharetra.
              </p>
              <p class="font-semibold text-gray-700">Ms. Helen</p>
            </span>
          </div>
        </div>
      </div>
      <div class="flex justify-end">
        <img
          loading="lazy"
          alt="APS Quote"
          src="../assets/images/quote-in.png"
          class=""
        />
      </div>
    </div>
  </div> -->

  <!-- Footer Section -->

  <footer class="bg-black" aria-labelledby="footerHeading" #footer>
    <h2 id="footerHeading" class="sr-only">Footer</h2>
    <div
      class="lg:mx-auto py-16 px-4 sm:px-6 lg:py-16 lg:px-8 text-center lg:text-left"
    >
      <div class="lg:flex lg:flex-row">
        <div class="lg:w-1/4">
          <h3
            class="lg:text-sm text-base font-semibold text-gray-400 tracking-wider uppercase"
          >
            ABOUT
          </h3>
          <p
            class="lg:text-sm text-base tracking-wider text-white font-thin mt-4"
          >
            Agofure parcel services is a representative logistics operator
            providing full range of service in the sphere of customs cargo and
            transportation world wide.
          </p>
        </div>
        <div class="lg:w-2/4 footer-links">
          <div
            class="lg:flex lg:flex-row lg:space-x-10 flex-wrap lg:justify-around lg:space-y-0 space-y-12"
          >
            <div>
              <h3
                class="lg:text-sm text-base font-semibold text-gray-400 tracking-wider uppercase"
              >
                QUICK LINKS
              </h3>
              <ul class="mt-4 space-y-4">
                <li>
                  <a
                    href="#"
                    class="lg:text-sm text-base tracking-wide text-white font-thin"
                  >
                    Home
                  </a>
                </li>

                <li>
                  <a
                    href="#"
                    class="lg:text-sm text-base tracking-wide text-white font-thin"
                  >
                    Services
                  </a>
                </li>

                <li>
                  <a
                    href="#"
                    class="lg:text-sm text-base tracking-wide text-white font-thin"
                  >
                    About Us
                  </a>
                </li>

                <li>
                  <a
                    href="#"
                    class="lg:text-sm text-base tracking-wide text-white font-thin"
                  >
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>

            <div>
              <h3
                class="lg:text-sm text-base font-semibold text-gray-400 tracking-wider uppercase"
              >
                OUR SERVICES
              </h3>
              <ul class="mt-4 space-y-4">
                <li>
                  <a
                    href="#"
                    class="lg:text-sm text-base tracking-wide text-white font-thin"
                  >
                    Air Freight
                  </a>
                </li>

                <li>
                  <a
                    href="#"
                    class="lg:text-sm text-base tracking-wide text-white font-thin"
                  >
                    Sea Freight
                  </a>
                </li>

                <li>
                  <a
                    href="#"
                    class="lg:text-sm text-base tracking-wide text-white font-thin"
                  >
                    Road Freight
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h3
                class="lg:text-sm text-base font-semibold text-gray-400 tracking-wider uppercase"
              >
                QUICK TOOLS
              </h3>
              <ul class="mt-4 space-y-4">
                <li>
                  <a
                    href="#"
                    class="lg:text-sm text-base tracking-wide text-white font-thin"
                  >
                    Tracking
                  </a>
                </li>

                <li>
                  <a
                    href="#"
                    class="lg:text-sm text-base tracking-wide text-white font-thin"
                  >
                    Request a Quote
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="lg:w-1/4">
          <h3
            class="lg:text-sm text-base font-semibold text-gray-400 tracking-wider uppercase"
          >
            QUICK CONTACT
          </h3>
          <p
            class="lg:text-sm text-base tracking-wide text-white font-thin mt-4"
          >
            If you have questions or would like to make an enquiry, feel free to
            contact us on:
          </p>
          <div class="flex mt-8 phone-deets">
            <img
              loading="lazy"
              alt="APS Phone NUmber"
              src="../assets/images/phone.png"
            />
            <h3 class="text-lg text-white font-bold ml-2">+234-706 485 692</h3>
          </div>
        </div>
      </div>

      <div class="mt-8 pt-8 md:flex md:items-center md:justify-center">
        <p class="mt-8 text-base text-center text-gray-400 md:mt-0 md:order-1">
          &copy; 2021 Agofure Parcel Services. All Rights Reserved.
        </p>
      </div>
    </div>
  </footer>
</div>
