import { ReactiveFormsModule } from '@angular/forms';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertsComponent } from './alerts/alerts.component';
import { LoaderComponent } from './loader/loader.component';



@NgModule({
  declarations: [AlertsComponent, LoaderComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
  ],
  exports: [AlertsComponent, LoaderComponent],
})
export class SharedModule {}
