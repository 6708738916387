import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { catchError } from "rxjs/operators";
import { ApiModel, TerminalModel, TrackingModel } from "./app.model";
import { ErrorHandlers } from "./error.handler";

@Injectable({
  providedIn: "root",
})
export class AppService {
  baseUrl = `https://api.apsswift.com/api/PublicPortal`;

  constructor(private readonly httpClient: HttpClient) {}

  fetchWayBillData(model): Observable<ApiModel<TrackingModel>> {
    return this.httpClient
      .get<ApiModel<TrackingModel>>(`${this.baseUrl}/track/${model}`)
      .pipe(catchError(ErrorHandlers.handleApiError));
  }

  getTerminals(): Observable<ApiModel<TerminalModel[]>> {
    return this.httpClient
      .get<ApiModel<TerminalModel[]>>(`${this.baseUrl}/GetActiveTerminal`)
      .pipe(catchError(ErrorHandlers.handleApiError));
  }

  getPrice(model): Observable<ApiModel<any>> {
    return this.httpClient
      .post<ApiModel<any>>(`${this.baseUrl}/ShipmentPrice`, model)
      .pipe(catchError(ErrorHandlers.handleApiError));
  }
}
