import { PrimaryLocation } from "../models/location.model";

export const AllLocations: PrimaryLocation[] = [
    { title: 'Lagos', locations: [
        {location: 'Agofure Parcel Services, Mile II', url: ''},
        {location: 'Agofure Parcel Services, Signal Barracks(Beside Army Signal Barracks)', url: ''},
        {location: 'Agofure Parcel Services, Ojota(By Total Filling Station, Ojota)', url: ''},
        {location: 'Agofure Parcel Services, Jibowu(Banex Plaza, By Fadeyi Bus Stop, Jibowu)', url: ''},
        {location: 'Agofure Parcel Services, Iyana-Ipaja(Along Lagos/Abeokuta Expressway, By Ipaja Bus Stop)', url: ''},
        {location: 'Agofure Parcel Services, Ajah(Before Abraham Adesanya Roundabout, Ajah)', url: ''},
    ] },

    { title: 'PortHarcourt', locations: [
        {location: 'Agofure Parcel Services, Waterlines(Aba Expressway, Waterline Junction, Olu Obasanjo Road, Opp. Prudent Bank)', url: ''},
        {location: 'Agofure Parcel Services, Choba(Uniport Bus Stop, Behind Mr. Biggs, Choba)', url: ''},
        {location: 'Agofure Parcel Services, Eleme(By Oil Mill Market, Eleme Junction)', url: ''},
        {location: 'Agofure Parcel Services, Rumuokoro(Along East/W Road, Opp. Ecobank, Rumuokoro Junction)', url: ''},
    ] },

    { title: 'Asaba', locations: [
        {location: 'Agofure Parcel Services, Asaba(Ibusa Road, Opp. NIRA Group of Companies)', url: ''},
    ] },

    { title: 'Enugu', locations: [
        {location: 'Agofure Parcel Services, Enugu(By Ogbete Main Market, Holy Ghost)', url: ''},
    ] },

    { title: 'Onitsha', locations: [
        {location: 'Agofure Parcel Services, Onitsha(Opp. Okpoko Junction, Upper Iweka, By Express)', url: ''},
    ] },

    { title: 'Ughelli', locations: [
        {location: 'Agofure Parcel Services, Ughelli(No 272, Ughelli/Patani Expressway, Ughelli)', url: ''},
    ] },

    { title: 'Sapele', locations: [
        {location: 'Agofure Parcel Services, Sapele(By Zenith Bank, Okirigwe, Sapele)', url: ''},
    ] },

    { title: 'Bayelsa', locations: [
        {location: 'Agofure Parcel Services, Yenegoa(By Tonbia Roundabout, Yenegoa, Bayelsa)', url: ''},
    ] },

    { title: 'Abraka', locations: [
        {location: 'Agofure Parcel Services, Abraka(Opp. Swag City Hotel, Along Abraka/Agbor Expressway, Abraka)', url: ''},
    ] },
  ];