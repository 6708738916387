import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import {
  ApiModel,
  QuoteItem,
  TerminalModel,
  Tracking,
  TrackingModel,
} from "src/assets/utils/app.model";
import { AppService } from "src/assets/utils/app.service";
import { PrimaryLocation } from "./models/location.model";
import { AlertService } from "./shared/alerts";
import { AllLocations } from "./utils/app.constants";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  loading = false;
  navbarOpen = false;
  priceLoading = false;

  homeForm: FormGroup;
  modalForm: FormGroup;

  pickupTerminals: PrimaryLocation[]= AllLocations;

  terminals: TerminalModel[];
  tableData: Tracking[];

  title = "aaps";
  waybillNumber: string = null;
  totalPrice = 0.0;

  quoteData: QuoteItem = {
    departureTerminalId: null,
    destinationTerminalId: null,
    shipmentItems: [
      {
        id: 0,
        description: "REGULAR",
        weight: null,
        quantity: 1,
      },
    ],
  };

  options = {
    autoClose: false,
    keepAfterRouteChange: false,
  };

  constructor(
    private readonly alertService: AlertService,
    private readonly appService: AppService
  ) {}

  ngOnInit() {
    this.buildForm();
    this.getDestinationData();
  }

  buildForm(): void {
    this.homeForm = new FormGroup({
      trackingForm: new FormControl(null, [Validators.required]),
      departureTerminalId: new FormControl(null, [Validators.required]),
      destinationTerminalId: new FormControl(null, [Validators.required]),
      weight: new FormControl(null, [Validators.required]),
    });
  }

  toggleNavbar() {
    this.navbarOpen = !this.navbarOpen;
  }

  scrollToElement($element): void {
    this.navbarOpen = false;
    $element.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
  }

  getTracking() {
    this.loading = true;
    const payload = this.homeForm.controls["trackingForm"].value;

    this.appService.fetchWayBillData(payload).subscribe(
      (response: ApiModel<TrackingModel>) => {
        this.loading = false;
        this.waybillNumber = response.result.shipment.waybill;
        this.tableData = response.result.trackings;
      },
      (error) => {
        this.loading = false;
        this.alertService.error(error.toString());
      }
    );
  }

  getDestinationData() {
    this.appService
      .getTerminals()
      .subscribe((result: ApiModel<TerminalModel[]>) => {
        this.terminals = result.result;
      });
  }

  getTotalPrice() {
    this.fillValues();
    this.priceLoading = true;

    this.appService.getPrice(this.quoteData).subscribe(
      (result: ApiModel<any>) => {
        this.priceLoading = false;
        this.totalPrice = result.result.grandTotal;
      },
      (error) => {
        this.priceLoading = false;
      }
    );
  }

  fillValues() {
    this.quoteData.departureTerminalId = Number(
      this.homeForm.controls["departureTerminalId"].value
    );
    this.quoteData.destinationTerminalId = Number(
      this.homeForm.controls["destinationTerminalId"].value
    );
    this.quoteData.shipmentItems[0].weight = Number(
      this.homeForm.controls["weight"].value
    );
  }

  get isFormValid(){
    return (
    this.homeForm.controls["departureTerminalId"].dirty && 
    this.homeForm.controls["destinationTerminalId"].dirty &&
    this.homeForm.controls["weight"].dirty)
  }
}
